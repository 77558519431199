import { t } from 'i18next';
import countBy from 'lodash/countBy';
import transform from 'lodash/transform';
import { Duration } from 'luxon';

import { type BookingStopStatusDisplayable } from '@/entity/journey/stop/BookingStop';

// t('stop.leg_details.status.emile_weber_night_overlapped.title')
// t('stop.leg_details.status.emile_weber_night_overlapped.description')
// t('stop.leg_details.status.max_single_driver_time_exceeded.title')
// t('stop.leg_details.status.max_single_driver_time_exceeded.description')
// t('stop.leg_details.status.driver_break.title')
// t('stop.leg_details.status.driver_break.description_one')
// t('stop.leg_details.status.max_driving_time_exceeded.title')
// t('stop.leg_details.status.max_driving_time_exceeded.description')
// t('stop.leg_details.status.max_operating_time_exceeded.title')
// t('stop.leg_details.status.max_operating_time_exceeded.description')
// t('stop.leg_details.status.consider_split_route.title')
// t('stop.leg_details.status.consider_split_route.description')
// t('stop.leg_details.status.split_route.title')
// t('stop.leg_details.status.split_route.description')
// t('stop.leg_details.status.local_bus_and_split_route.title')
// t('stop.leg_details.status.local_bus_and_split_route.description')

export type LegDetailsStatus =
    | 'EMILE_WEBER_NIGHT_OVERLAPPED'
    | 'MAX_SINGLE_DRIVER_TIME_EXCEEDED'
    | 'DRIVER_BREAK'
    | 'MAX_DRIVING_TIME_EXCEEDED'
    | 'MAX_OPERATING_TIME_EXCEEDED';

export type Statuses = LegDetailsStatus | BookingStopStatusDisplayable;

function tStatus(status: Statuses, part: 'title' | 'description'): string {
    return `stop.leg_details.status.${status.toLocaleLowerCase()}.${part}`;
}

export interface DisplayStatus {
    t_title: string;
    t_description: string;
    severity: StatusSeverity;
}

export function displayStatus(status: Statuses): DisplayStatus {
    return {
        t_title: tStatus(status, 'title'),
        t_description: tStatus(status, 'description'),
        severity: StatusSeverityMap[status],
    };
}

export type StatusSeverity = 'info' | 'warning' | 'error';

const StatusSeverityMap: Record<Statuses, StatusSeverity> = {
    SPLIT_ROUTE: 'info',
    CONSIDER_SPLIT_ROUTE: 'info',
    LOCAL_BUS_AND_SPLIT_ROUTE: 'info',
    // always show if there is a break
    DRIVER_BREAK: 'info',

    // only show if there are no errors
    MAX_DRIVING_TIME_EXCEEDED: 'warning',
    MAX_SINGLE_DRIVER_TIME_EXCEEDED: 'warning',
    EMILE_WEBER_NIGHT_OVERLAPPED: 'warning',

    MAX_OPERATING_TIME_EXCEEDED: 'error',
};

export class LegDetails {
    public statuses: LegDetailsStatus[];

    public distanceInMeters: number;

    public durationInSecondsWithBreaks: number;

    public durationWithBreaks: Duration;

    constructor(json: Record<string, any>) {
        this.statuses = json.statuses ?? [];
        this.distanceInMeters = json.distanceInMeters;
        this.durationInSecondsWithBreaks = json.durationInSecondsWithBreaks;
        this.durationWithBreaks = Duration.fromObject({ seconds: this.durationInSecondsWithBreaks }).normalize();
    }

    public statusSeverity(): StatusSeverity {
        if (this.statuses.some(s => StatusSeverityMap[s] === 'error')) return 'error';
        if (this.statuses.some(s => StatusSeverityMap[s] === 'warning')) return 'warning';
        return 'info';
    }

    public getStatusesBySeverity(severity: StatusSeverity): LegDetailsStatus[] {
        return this.statuses.filter(s => StatusSeverityMap[s] === severity);
    }

    public getDriverBreak(): DisplayStatus | undefined {
        const driverBreak = this.statuses.find(s => s === 'DRIVER_BREAK');

        if (driverBreak) return displayStatus(driverBreak);
    }

    public getAlerts(isEmileWeber: boolean): DisplayStatus[] {
        // - MAX_DRIVING_TIME_EXCEEDED should be preferred over MAX_SINGLE_DRIVER_TIME_EXCEEDED if both statuses are present
        // - EMILE_WEBER_NIGHT_OVERLAPPED should only be shown on the emile weber integration
        const warning =
            this.statuses.find(s => s === 'MAX_DRIVING_TIME_EXCEEDED') ??
            this.statuses.find(s => s === 'EMILE_WEBER_NIGHT_OVERLAPPED' && isEmileWeber) ??
            this.statuses.find(s => s !== 'EMILE_WEBER_NIGHT_OVERLAPPED' && StatusSeverityMap[s] === 'warning');
        const error = this.statuses.find(s => StatusSeverityMap[s] === 'error');

        const displayable: DisplayStatus[] = [];

        if (error) displayable.push(displayStatus(error));
        else if (warning) displayable.push(displayStatus(warning));

        return displayable;
    }

    public getTranslatedStatuses(): PartialRecord<LegDetailsStatus, string> {
        return transform(countBy(this.statuses), (result, count, status) => {
            result[status as LegDetailsStatus] = [
                t(tStatus(status as LegDetailsStatus, 'title'), { count } as any),
                t(tStatus(status as LegDetailsStatus, 'description'), { count } as any),
            ].join(': ');
            return result;
        });
    }

    public hasStatus(status: LegDetailsStatus): boolean {
        return this.statuses.includes(status);
    }

    public hasErrorOrWarning() {
        return this.statuses.some(s => StatusSeverityMap[s] !== 'info');
    }
}
